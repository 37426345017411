import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Grid, Paper, TextField, CircularProgress } from "@mui/material";
import { Colors } from "../../constants/Colors";
import Header from "../../globals/components/header/Header";
import { Images } from "../../constants/Images";
import CustomButton from "../../globals/components/customButton/CustomButton";
import { useDispatch, useSelector } from "../../store";
import { getTipsAccountApi, sendTipApi } from "../../services/apiService";

const PaymentId = '57e7ff2a-63e9-4623-b219-486afef58a40';

const ChooseTipAmount = () => {
    const dispatch = useDispatch();

    const accountDetails = useSelector((state: any) => state?.tipAccountDetails?.accountDetails)
    const isLoading = useSelector((state: any) => state?.tipAccountDetails?.isLoading)
    const minAmount = accountDetails?.tip_limits?.min;
    const maxAmount = accountDetails?.tip_limits?.max;

    const [selectedAmount, setSelectedAmount] = useState<string | number>();
    const [error, setError] = useState<boolean>(false);

    const getTipAccountDetails = () => {
        const params = {
            payment_id: PaymentId,
            region: 'UAE'
        };

        dispatch(getTipsAccountApi(params));
    };

    useEffect(() => {
        getTipAccountDetails()
    }, [])

    const handleChange = (event: any) => {
        const value = event.target.value;

        if (value < minAmount) {
            setSelectedAmount(value);
            setError(true);
        } else if (value > maxAmount) {
            setSelectedAmount(value);
            setError(true);
        } else {
            setSelectedAmount(value);  // Update the selected amount
            setError(false);  // Clear error if value is within range
        }
    };


    const handleSendTip = () => {
        const tipParams = {
            amount: selectedAmount,
            country: "UAE",
            payment_id: PaymentId,
            payment_method: "creditcard"
        }

        const onSucess = (res: any) => {
            window.location.href = res?.payment_url;
        }

        dispatch(sendTipApi(tipParams, onSucess))
    }

    const getTipEmojiLabel = (tipAmount: any) => {
        if (tipAmount >= minAmount && tipAmount < accountDetails?.suggested_tips?.tip_2) {
            return { label: "Humble", icon: Images.Humble };
        } else if (tipAmount >= accountDetails?.suggested_tips?.tip_2 && tipAmount < accountDetails?.suggested_tips?.tip_3) {
            return { label: "Icon", icon: Images.Icon };
        } else if (tipAmount >= accountDetails?.suggested_tips?.tip_3 && tipAmount <= maxAmount) {
            return { label: "Legend", icon: Images.Legend };
        } else {
            return { label: "", icon: "" }; // No emoji displayed
        }
    }

    const { label, icon } = getTipEmojiLabel(selectedAmount);

    return (
        <Box
            sx={{
                // flex: 1,
                margin: 0,
                height: "100%",
                padding: '0 16px',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: Colors.white,
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {
                isLoading ? (
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {/* Header */}
                        <Header />
                        <Box flex={1} display={'flex'} flexDirection={'column'} paddingBottom={1.2}>
                            {/* Amount Section */}
                            <Box mt={3} mb={2} alignItems={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'} flex={2}>
                                <Box position={'relative'} alignItems={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                                    <Box sx={{
                                        height: 25,
                                        width: 45,
                                        backgroundColor: Colors.gray,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}>
                                        <Typography variant="overline" color="textSecondary">
                                            SAR
                                        </Typography>
                                    </Box>

                                    <TextField
                                        variant="outlined"
                                        value={selectedAmount}
                                        onChange={handleChange}
                                        placeholder="0"
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border: 'none', // Remove the border
                                                },
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                textAlign: "center",
                                                fontSize: 48,
                                                fontWeight: "bold",
                                                padding: 0
                                            },
                                        }}
                                        type="number"
                                    />

                                    {label && icon && (
                                        <Box position={'absolute'} right={0} bottom={18} alignItems="center" display={"flex"} flexDirection={'column'}>
                                            <Box sx={{ transform: 'rotate(20deg)' }} bgcolor={Colors.gray} borderRadius={4} paddingY={1} paddingX={2} alignItems={'center'} justifyContent={'center'}>
                                                <Typography textAlign={'center'} fontWeight={'600'} fontSize={12} color={Colors.accent100} fontFamily={'Poppins'} >{label}</Typography>
                                            </Box>
                                            <img src={icon} alt={label} width={42} height={42} />
                                        </Box>
                                    )}
                                </Box>
                                {/* Predefined Amount Buttons */}
                                <Grid container justifyContent="space-between" width={'100%'} marginTop={3}>
                                    {[accountDetails?.suggested_tips?.tip_1, accountDetails?.suggested_tips?.tip_2, accountDetails?.suggested_tips?.tip_3].map((amount) => (
                                        <Grid item key={amount}>
                                            <Button
                                                variant={"contained"}
                                                onClick={() => setSelectedAmount(amount)}
                                                sx={{
                                                    borderRadius: "12px",
                                                    minWidth: 85,
                                                    minHeight: 40,
                                                    backgroundColor: amount === selectedAmount ? Colors.brandPrimary : Colors.white,
                                                    color: amount === selectedAmount ? Colors.white : Colors.primaryGray,
                                                    borderColor: Colors.grayBorder,
                                                    fontSize: 18,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {amount}
                                            </Button>
                                        </Grid>
                                    ))}
                                </Grid>
                                {error && (
                                    <Typography color="error" marginTop={2} fontFamily={'Poppins'}>
                                        Please enter an amount between {minAmount} & {maxAmount}.
                                    </Typography>
                                )}
                            </Box>

                            <Box flex={1} display={'flex'} justifyContent={'end'} flexDirection={'column'}>
                                {/* Security and PayTabs */}
                                <Box mt={3} mb={3} display="flex" justifyContent="center" alignItems="center">
                                    <Paper
                                        variant="outlined"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderColor: Colors.grayBorder
                                        }}
                                    >
                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <img src={Images.Security} alt="Security" width={20} height={20} />
                                            <Typography sx={{ fontFamily: 'Poppins', color: Colors.gray1, fontSize: 13, paddingLeft: '4px', paddingRight: '8px' }}>
                                                100% Secure with
                                            </Typography>
                                            <img src={Images.PayTabs} alt="PayTabs" width={62} height={20} />
                                        </Box>
                                    </Paper>
                                </Box>


                                {/* Send a Tip Button */}
                                <CustomButton
                                    btnText="SEND A TIP"
                                    bgColor={selectedAmount || error ? Colors.accent100 : Colors.disabled}
                                    isDisabled={!selectedAmount || selectedAmount === '0' || error}
                                    handleBtn={() => handleSendTip()}
                                />
                            </Box>
                        </Box>
                    </>
                )
            }
        </Box>
    );
};

export default ChooseTipAmount;
