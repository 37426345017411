import { green } from "@mui/material/colors";

export const Colors = {
  primaryColor: '#2C113A',
  white: '#ffffff',
  secondaryGray: '#444546',
  primaryGray: '#1E2324',
  gray: '#F3F6F7',
  gray1: '#757575',
  gray2: '#FCFDFD',
  green: '#039855',
  green1: '#e6f4ef',
  grayBorder: '#FCFDFD',
  brandPrimary: '#1C4956',
  accent100: '#0E323E',
  disabled: '#8b9598'
};
