import { Box, Typography } from '@mui/material';
import React from 'react';
import { Colors } from '../../../constants/Colors';
import { Images } from '../../../constants/Images';
import { useSelector } from '../../../store';

const Header: React.FC = () => {
  const accountDetails = useSelector((state: any) => state?.tipAccountDetails?.accountDetails)

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" width={'100%'}>
      <Box display="flex" alignItems="center">
        <img src={accountDetails?.account_photo} alt="Logo" style={{ borderRadius: "50%", marginRight: 8 }} height={24} width={24} />
        <Typography fontWeight={'600'} fontFamily='Poppins' color={Colors.primaryColor} fontSize={16}>{accountDetails?.display_name}</Typography>
      </Box>
      <Box display={'flex'}>
        <Typography fontWeight={'normal'} fontFamily='Poppins' fontSize={11} marginRight={1} color={Colors.secondaryGray}>Powered by </Typography>
        <img src={Images.TappLogo} alt="Tapp Logo" width={52} height={14} />
      </Box>
    </Box>
  );
};

export default Header;
