import { Box, Button, Card, CardContent, CardMedia, Divider, Stack, Typography } from "@mui/material"
import { Colors } from "../../constants/Colors"
import { Images } from "../../constants/Images"

const PaymentSuccess = () => {
    return (
        <Box
            sx={{
                // display: "flex",
                // flexDirection: "column",
                backgroundColor: Colors.gray2,
                alignItems: 'center',
            }}
        >
            {/* Header */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: Colors.green,
                    px: 2,
                    py: 1,
                }}
            >
                <Box display={'flex'} alignItems={'center'}>
                    <img src={Images.Success} alt={'Success'} width={20} height={20} />
                    <Typography color={Colors.white} fontFamily={'Poppins'} fontWeight={'500'} fontSize={14} marginLeft={1}>
                        Transaction successful
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    size="small"
                    sx={{
                        boxShadow: 'none',
                        "&:hover": { backgroundColor: "transparent" },
                        background: 'transparent',
                        border: '1px solid white',
                        borderRadius: '8px',
                        fontFamily: 'Poppins',
                        fontSize: '13px'
                    }}
                >
                    Tip again
                </Button>
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} marginY={2}>
                <Typography fontWeight={'normal'} fontFamily='Poppins' fontSize={11} marginRight={1} color={Colors.secondaryGray}>Powered by </Typography>
                <img src={Images.TappLogo} alt="Tapp Logo" width={52} height={14} />
            </Box>
            {/* Card Section */}
            <Card sx={{ mx: 2, borderRadius: "12px", overflow: "hidden", }}>
                <CardContent sx={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                    <Box>
                        <Typography fontWeight={'700'} fontSize={14} fontFamily={'Poppins'}>
                            Natalie
                        </Typography>
                        <Typography fontSize={14} fontFamily={'Poppins'}>
                            Receptionist
                        </Typography>
                    </Box>
                    <CardMedia
                        component="img"
                        src="https://via.placeholder.com/50"
                        alt="Profile"
                        sx={{
                            width: 50,
                            height: 50,
                            borderRadius: "50%",
                        }}
                    />
                </CardContent>
                <CardContent sx={{ paddingX: 2, paddingY: 0 }}>
                    <Typography fontWeight={'600'} fontSize={24} fontFamily={'Poppins'} color={Colors.primaryGray} display={'flex'} alignItems={'center'}>
                        100.00 <Typography fontWeight={'600'} fontSize={24} fontFamily={'Poppins'} color={Colors.gray1} marginLeft={1}>SAR</Typography>
                    </Typography>
                    <Box bgcolor={Colors.green1} borderRadius={'10px'} padding={2} alignItems={'center'} marginY={2}>
                        <Typography
                            textAlign={'center'}
                            variant="body1"
                            color={Colors.primaryGray}
                            fontFamily={'Poppins'}
                            fontSize={14}
                            fontWeight={'500'}
                        >
                            “Thank you for the thoughtful gesture 🌻”
                        </Typography>
                        <Typography
                            textAlign={'center'}
                            variant="body1"
                            color={Colors.primaryGray}
                            fontFamily={'Poppins'}
                            fontSize={14}
                            fontWeight={'500'}
                        >
                            ~ Natalie
                        </Typography>
                    </Box>
                </CardContent>
                <Divider />
                <Box margin={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography fontWeight={'500'} fontFamily={'Poppins'} fontSize={14}>More details</Typography>

                    <Box display={'flex'} alignItems={'center'}>
                        <img src={Images.Share} alt="Tapp Logo" width={20} height={20} />
                        <Typography fontFamily={'Poppins'} fontSize={14} fontWeight={'500'}>Share</Typography>
                    </Box>
                </Box>
            </Card>

            {/* Contact Support */}
            <Box margin={2} bgcolor={Colors.white} boxShadow={1} borderColor={Colors.white} padding={2} borderRadius={'12px'}>
                <Typography fontFamily={'Poppins'} fontSize={14} fontWeight={'500'}>
                    Contact Tapp support
                </Typography>
            </Box>

            {/* Footer */}
            {/* <Box mt={4} textAlign="center">
                <Typography variant="subtitle2" gutterBottom>
                    Powered by tapp.
                </Typography>
                <Stack direction="row" justifyContent="center" spacing={2}>
                    <img
                        src="https://via.placeholder.com/80x25?text=App+Store"
                        alt="App Store"
                    />
                    <img
                        src="https://via.placeholder.com/80x25?text=Google+Play"
                        alt="Google Play"
                    />
                </Stack>
                <Box mt={2}>
                    <Stack direction="row" justifyContent="center" spacing={1}>
                        <Typography variant="body2" color="textSecondary">
                            Connect with us:
                        </Typography>
                        <Stack direction="row" spacing={1}>
                            <img
                                src="https://via.placeholder.com/20?text=LinkedIn"
                                alt="LinkedIn"
                            />
                            <img
                                src="https://via.placeholder.com/20?text=Instagram"
                                alt="Instagram"
                            />
                            <img
                                src="https://via.placeholder.com/20?text=Twitter"
                                alt="Twitter"
                            />
                        </Stack>
                    </Stack>
                </Box>
            </Box> */}
        </Box>
    )
}

export default PaymentSuccess