import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TipRating from '../components/tipRating/TipRating';
import AppRating from '../components/appRating/AppRating';
import ChooseTipAmount from '../components/chooseTipAmount/ChooseTipAmount';
import PaymentSuccess from '../components/paymentSuccess/PaumentSuccess';

const MainRoute: React.FC = () => {
    return (
        <Router>
            <main style={{ padding: 0, height: '100vh' }}>
                <Routes>
                    <Route path="/" element={<ChooseTipAmount />} />
                    <Route path="/tip-rating" element={<TipRating />} />
                    <Route path="/app-rating" element={<AppRating />} />
                    <Route path="/payment-success" element={<PaymentSuccess />} />
                </Routes>
            </main>
        </Router>
    );
};

export default MainRoute;
