import axios, { AxiosResponse } from 'axios';

export const callAPI = async (
    method: 'GET' | 'POST' | 'DELETE' | 'PUT',
    url: string,
    onAccessFail: (error?: any) => void,
    onAccessSuccess: (response: AxiosResponse) => void,
    values?: any
): Promise<void> => {
    const formData: any = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    if (values !== undefined && values !== '') {
        formData.data = values;
    }
    console.log(url, formData);

    try {
        switch (method) {
            case 'GET':
                const getResponse = await axios.get(url, formData);

                if (getResponse.status === 200) {
                    onAccessSuccess(getResponse);
                } else {
                    onAccessFail(getResponse);
                }
                break;

            case 'POST':
                const postResponse = await axios.post(url, values, formData);
                if (postResponse.status === 200 || postResponse.status === 201) {
                    onAccessSuccess(postResponse);
                } else if (postResponse.status === 429) {
                    onAccessFail(postResponse);
                } else {
                    onAccessFail(postResponse);
                }
                break;

            case 'DELETE':
                const deleteResponse = await axios.post(url, values, formData);
                if (deleteResponse.status === 200) {
                    onAccessSuccess(deleteResponse);
                } else {
                    onAccessFail();
                }
                break;

            case 'PUT':
                const updateResponse = await axios.put(url, values, formData);

                if (updateResponse.status === 200) {
                    onAccessSuccess(updateResponse);
                } else {
                    onAccessFail(updateResponse);
                }
                break;

            default:
                break;
        }
    } catch (error) {
        if (error) {
            console.log('error======', error);
            onAccessFail(error);
        }
        throw error;
    }
};
