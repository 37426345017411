import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import MainRoute from './router/Route';
import '../src/theme/styles/Styles.css';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <MainRoute />
    </Provider>
  );
};

export default App;
