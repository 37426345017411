import { Button } from "@mui/material";
import { Colors } from "../../../constants/Colors";
import React from "react";

// Define the props interface
interface CustomButtonProps {
    btnText: string;
    bgColor?: string; // optional bgColor
    handleBtn: () => void;
    isDisabled?: boolean; // optional disabled
}

// Convert CustomButton component to TypeScript with the props interface
const CustomButton: React.FC<CustomButtonProps> = ({ btnText, bgColor, handleBtn, isDisabled }) => {
    return (
        <Button
            variant="contained"
            fullWidth
            onClick={handleBtn}
            disabled={isDisabled}
            sx={{
                padding: "12px 0",
                borderRadius: "100px",
                backgroundColor: bgColor, // provide a fallback color if needed
                fontWeight: 600,
                color: Colors.white,
                fontFamily: "Poppins",
                fontSize: 16,
            }}
        >
            {btnText}
        </Button>
    );
};

export default CustomButton;
