import TappLogo from '../assets/svg/TappLogo.svg';
import Security from '../assets/svg/Security.svg';
import PayTabs from '../assets/svg/PayTabs.svg';
import Icon from '../assets/svg/Icon.svg';
import Humble from '../assets/svg/Humble.svg';
import Legend from '../assets/svg/Legend.svg';
import Success from '../assets/svg/Success.svg';
import Share from '../assets/svg/Share.svg';

export const Images = {
    TappLogo,
    Security,
    PayTabs,
    Icon,
    Humble,
    Legend,
    Success,
    Share
}