import { callAPI } from "../../hooks/api";
import { setAccountDetails, setLoading } from "../../store/slices/accountDetails/AccountDetailsSlice";
import { ApiUrls } from "../endpoints";

// GET Method

export const getTipsAccountApi =
    (data: any) =>
        async (dispatch: any) => {
            dispatch(setLoading(true));
            const onAccessFail = (err: any) => {
                console.error("API call failed:", err.response || err.message || err);
                dispatch(setLoading(false));
            };
            const onAccessSuccess = (res: any) => {
                dispatch(setAccountDetails(res?.data?.data));
                dispatch(setLoading(false));
            };

            await callAPI(
                'GET',
                `${ApiUrls.getTipsAccount}/${data?.payment_id}?region=${data?.region}`,
                onAccessFail,
                onAccessSuccess
            );
        };

// POST Method

export const sendTipApi =
    (data: any, onSucess: any) =>
        async (dispatch: any) => {
            const onAccessFail = (err: any) => {
                console.error("API call failed:", err.response || err.message || err);
            };
            const onAccessSuccess = (res: any) => {
                onSucess(res?.data?.data)
                console.log("API call succeeded:", res?.data);
            };

            await callAPI(
                'POST',
                ApiUrls.sendTip,
                onAccessFail,
                onAccessSuccess,
                data
            );
        };