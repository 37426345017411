import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountData } from '../../../utils/type';

export interface State {
    accountDetails: AccountData[];
    isLoading: boolean;
}

const initialState: State = {
    accountDetails: [],
    isLoading: false,
};

const slice = createSlice({
    name: 'tip-account',
    initialState,
    reducers: {
        setAccountDetails(state: State, action: PayloadAction<any[]>) {
            state.accountDetails = action.payload;
        },
        setLoading(state: State, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        }
    },
});

const { actions, reducer } = slice;

export const {
    setAccountDetails,
    setLoading
} = actions;
export default reducer;
